import React from "react";
import { Link } from "react-router-dom";
import "../styles/Style.css";
import "../styles/Header.css";
import { Helmet } from "react-helmet";

const Header = () => {
  return (
    <header className="header">
      <Helmet>
        <title>Bharat Coding Academy - Learn to Code with Experts</title>
        <meta
          name="description"
          content="Unlock a world of coding opportunities with expert-led courses at Bharat Coding Academy."
        />
      </Helmet>
      <div className="header-container">
        <Link to="/">
          <img
            src="https://i.ibb.co/VjY8Fty/logo-removebg.png"
            alt="logo"
            border="0"
            className="header-logo"
          />
        </Link>
      </div>
    </header>
  );
};
export default Header;
