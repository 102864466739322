// Footer.js
import React from "react";
import "../styles/Style.css";
import "../styles/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <p className="footer-text">
        &copy; 2023 Bharat Coding Academy. All rights reserved.
      </p>
    </footer>
  );
};
export default Footer;
