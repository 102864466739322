import React from "react";
import "../styles/Style.css";
import "../styles/WhyChooseUs.css";
import { Helmet } from 'react-helmet';

const Feature = ({ title, description, imageUrl, alt, reverse }) => (
  <div className={`feature ${reverse ? "reverse-feature" : ""}`}>
    <div className="feature-content">
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
    <div className="feature-image">
      <img src={imageUrl} alt={alt} className="why-image" />
    </div>
  </div>
);

const WhyChooseUs = () => {
  const features = [
    {
      title: "Practical Learning",
      description:
        "Embrace practical learning methodologies. Our courses focus on hands-on learning, ensuring you can apply theoretical knowledge in real-world scenarios.",
      imageUrl: "https://i.ibb.co/Ld65sKV/practical.png",
      alt: "Practical Learning",
    },
    {
      title: "Real-World Projects",
      description:
        "Apply your skills to real-world projects. Our curriculum is designed to give you hands-on experience, ensuring you're ready for challenges in the industry.",
      imageUrl: "https://i.ibb.co/6HwXnTt/realworld.png",
      alt: "Real-World Projects",
    },
    {
      title: "Industry-Standard Tools",
      description:
        "Gain proficiency in using industry-standard tools. Our courses cover the tools and technologies widely used in the field, ensuring you're well-equipped for your career.",
      imageUrl: "https://i.ibb.co/yg3KNjk/expirenced-instructor.png",
      alt: "Industry-Standard Tools",
    },
    {
      title: "No fluff, just the good stuff!",
      description:
        "I've designed my courses to be easy to understand, without any confusing technical language. They're straightforward, to the point, and free of unnecessary details - just the important stuff you need to succeed, explained in simple terms.",
      imageUrl: "https://i.ibb.co/2MgVHLg/content.png",
      alt: "Content",
    },
  ];

  return (
    <section id="why-choose-us" className="why-choose-us-section">
      <Helmet>
        <title>Bharat Coding Academy - Why Choose Us</title>
        <meta name="description" content="Discover why Bharat Coding Academy is the best choice for learning programming. Practical learning, real-world projects, and industry-standard tools await you." />
      </Helmet>
      <div className="why-choose-us-container">
        <h2>Why Choose Us</h2>
        <div className="features-container">
          {features.map((feature, index) => (
            <Feature key={index} {...feature} reverse={index % 2 !== 0} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
