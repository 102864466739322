// HeroSection.js
import React from "react";
import "../styles/Style.css";
import "../styles/Hero.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const HeroSection = () => {
  return (
    <section id="hero" className="hero-section">
      <Helmet>
        <title>Bharat Coding Academy - Upgrade Your Skills</title>
        <meta
          name="description"
          content="Upgrade your coding skills with expert-led courses. Discover the power of programming at Bharat Coding Academy."
        />
      </Helmet>
      <div className="hero-container">
        <h2>Upgrade Your Skills with Us</h2>
        <p>
          Unlock a world of opportunities with our expert-led courses in
          programming languages, databases, and frameworks.
        </p>
        <Link to="/courses">
          <button className="explore-button">Explore Courses</button>
        </Link>
      </div>
    </section>
  );
};

export default HeroSection;
