// CourseCard.js
import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import "../styles/CourseCard.css"

import Typography from "@mui/material/Typography";


function CourseCard({ title, image, description, url }) {
  return (
    <Card
      sx={{
        maxWidth: 346,
        borderRadius: 5,
        backgroundColor: "#f8f8f8",
        height: 400,
      }}
    >
      <CardMedia
        sx={{
          objectFit: "scale-down",
          height: 200,
        }}
        image={image}
        title={title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ height: 80 }}>
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        <a className="learn-more" href={url} target="_blank" >Learn More</a>
      </CardActions>
    </Card>
  );
}

export default CourseCard;
