import * as React from "react";
import CourseCard from "./CourseCard";
import "../styles/Course.css";

export function Course() {
  const courses = [
    {
      title: 'Python',
      image: 'https://i.ibb.co/6y6MJrR/python.png',
      description: 'Python, celebrated for readability and versatility, serves as a programming Swiss Army knife, essential for web development, data science, AI, and automation.',
      url: 'https://www.python.org/',
    },
    {
      title: 'Java',
      image: 'https://i.ibb.co/7ngfcLT/java.png',
      description: 'Java\'s "write once, run anywhere" mantra makes it a powerhouse for cross-platform applications, favored in enterprise-level systems and Android app development for its robustness and extensive ecosystem.',
      url: 'https://nodejs.org/en',
    },
    {
      title: 'Node.js',
      image: 'https://i.ibb.co/vdsD9VR/nodejs.png',
      description: 'Node.js revolutionizes server-side scripting, empowering developers to create high-performance, scalable web applications, APIs, and microservices with JavaScript for enhanced efficiency and real-time capabilities.',
      url: 'https://nodejs.org/en',
    },
    {
      title: 'Spring Boot',
      image: 'https://i.ibb.co/7KD5XkM/spring.png',
      description: 'Spring Boot simplifies Java application development, providing a convention-over-configuration approach for rapid creation of microservices, web applications, and enterprise solutions.',
      url:'https://spring.io/projects/spring-boot',
    },
    {
      title: 'Express.js',
      image: 'https://i.ibb.co/pP1YMsz/express.png',
      description: 'Express.js, a minimalist Node.js framework, streamlines the creation of scalable web applications and RESTful APIs, offering simplicity and flexibility for developers aiming to build high-performance server-side solutions.',
      url:'https://expressjs.com/',
    },
    {
      title: 'Linux Fundamentals',
      image: 'https://i.ibb.co/HtjvTQR/linux.png',
      description: 'Linux, the backbone of open-source systems, is crucial for secure and efficient system administration, scripting, and deployment in servers, embedded systems, and cloud infrastructure.',
      url:'https://www.linux.org/',
    }
  ];

  return (
    <div className="course-class">
      <div className="course-container">
        <h2 className="course-page-heading">Level Up Your Coding Skills</h2>
        <h3 className="course-page-text">
          Whether you want to excel in a programming language, backend
          framework, or strengthen your computer fundamentals, there is a course
          for you.
        </h3>
        <h3 className="all-courses">All Courses</h3>
        <div className="course-cards">
          {courses.map((course, index) => (
            <div className="individual-course" key={index}>
              <CourseCard
                title={course.title}
                image={course.image}
                description={course.description}
                url = {course.url}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}