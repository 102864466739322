import { Course } from "../components/Course";
import Footer from "../components/Footer";
import Header from "../components/Header";

export function Courses() {
  return (
    <div className="home-container">
      <Header />
      <Course />
      <Footer />
    </div>
  );
}
