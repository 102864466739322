import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import Testimonials from "../components/Testimonials";
import WhyChooseUs from "../components/WhyChooseUs";
import "../styles/Home.css";

export function Home() {
  return (
    <div className="home-container">
      <Header />
      <HeroSection />
      <WhyChooseUs />
      {/* <Testimonials /> */}
      <Contact />
      <Footer />
    </div>
  );
}
