// Contact.js
import React from "react";
import "../styles/Style.css";
import "../styles/Contact.css";

const Contact = () => {
  return (
    <div className="contact-section">
      
      <iframe title="google-form" className="google-form"
        src="https://docs.google.com/forms/d/e/1FAIpQLSdsvJT7OiwOgQshz2yNKZGBeskYm-P__2iw3QnA_UmfuA5sMQ/viewform?embedded=true"
        width="640"
        height="689"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Loading…
      </iframe>
    </div>
  );
};

export default Contact;
